import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import liff from '@line/liff/dist/lib';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LineProfile } from '../shared/models/account.models';
import { Customer } from '../shared/models/customer.model';

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    REST_API: string = environment.apiUrl + "/auth";

    constructor(
        private httpClient: HttpClient,
        private route: ActivatedRoute,
        private router: Router
    ) {
        // this.accountSubject = new BehaviorSubject<Account>(null);
        // this.account = this.accountSubject.asObservable();
    }

    getAuthToken(): string {
        return localStorage.getItem('@lyn_token')
    }
    getClientId(): string {
        return localStorage.getItem('@lyn_client_id')
    }
    getLineProfile(): LineProfile {
        return JSON.parse(localStorage.getItem('@lyn_profile'))
    }
    getUser(): Customer {
        return JSON.parse(localStorage.getItem('@lyn_customer'))
    }

    // setAccessToken(token) {
    //     localStorage.setItem('@lyn_token', token);
    // }
    setIdToken(value) {
        localStorage.setItem('@lyn_token', value);
    }
    setClientId(value) {
        localStorage.setItem('@lyn_client_id', value);
    }
    setLineProfile(profile) {
        localStorage.setItem('@lyn_profile', JSON.stringify(profile));
    }
    setUser(user: Customer) {
        return new Promise<void>((resolve, reject) => {
            localStorage.setItem('@lyn_customer', JSON.stringify(user));
            resolve()
        });


    }

    /**
     * Performs the auth
     * @param customerId id of user
     * @param lineUserId id of line user
     */
    authen(customerId: string, lineUserId: string): Observable<any> {
        const data = {
            username: customerId,
            password: lineUserId
        }
        return this.httpClient
            .post(this.REST_API + '/login', data)
            .pipe();
    }

    // login(customerId: string, lineUserId: string) {

    //     this.authen(customerId, lineUserId)
    //         .subscribe((res) => {

    //             const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    //             this.router.navigateByUrl(returnUrl);
    //             // const user = {
    //             //     customerId: customerId,
    //             //     token: res.access_token
    //             // }
    //             // localStorage.setItem('@lyn_authen', JSON.stringify(user));
    //             // console.log('set lyn authen', user);
    //         }, err => {
    //         });

    // }

    logout() {
        liff.logout();
        localStorage.clear();
    }

}
