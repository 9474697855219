// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultauth: "fakebackend",
  appUrl: 'https://www-dev.loveyounature.org',
  // appUrl: 'https://lyn-dev--220818-1320-wcjjn38t.web.app',
  apiUrl: "https://api-staging.loveyounature.org",
  shareUrl: "https://lyn-share.web.app",

  liffLyn: "1657119621-RjyLE3oj",
  liffSharePopup: "1657119621-3E2BYxna",
  missionUrl: "https://liff.line.me",
  lineOAUrl: "https://lin.ee/Ao6Ip2B",

  ecolifeToken: "453fbb46791ef8a0d540e5dc81601e9bdd569e5621015e52304f993a2eca10400a9355b8",

  firebaseConfig: {
    apiKey: "AIzaSyBT6DNIV9QlrKyLnUPqp5uqwH3rNFV4olg",
    domainPrefix: "lyndev.page.link",
    databaseURL: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    measurementId: "",
  },
};

