import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Customer } from "../shared/models/customer.model";
import { CustomerCfc } from "../shared/models/customercfc.model";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  // Node/Express API
  REST_API: string = environment.apiUrl + "/customer";
  token;

  constructor(private httpClient: HttpClient) { }

  // Ecolife
  ecolife(data: any): Observable<any> {
    let API_URL = `https://ioscan.ecolifeapp.com`;
    return this.httpClient
      .post(API_URL, data)
      .pipe();
  }

  // Add
  addCustomer(data: Customer): Observable<any> {
    let API_URL = `${this.REST_API}`;

    return this.httpClient
      .post(API_URL, data)
      .pipe();
  }

  // Add
  getMarriedImage(data: any): Observable<any> {
    let API_URL = `${environment.apiUrl}/image/marriedImage`;

    return this.httpClient
      .post(API_URL, data)
      .pipe();
  }

  // Get Single Object
  getCustomer(id: any): Observable<any> {
    let API_URL = `${this.REST_API}/${id}`;

    return this.httpClient.get(API_URL).pipe(
      map((res: any) => {
        return res || {};
      }),
      
    );
  }

  // Get Single Object
  findByLineUserId(id: any): Observable<any> {
    let API_URL = `${this.REST_API}/find/findByLineUserId?lineUserId=${id}`;

    return this.httpClient.get(API_URL).pipe(
      map((res: any) => {
        return res || null;
      }),
      
    );
  }

  // Update
  updateCustomer(cusId: string, data: any): Observable<any> {
    let API_URL = `${this.REST_API}/${cusId}`;

    return this.httpClient
      .patch(API_URL, data)
      .pipe();
  }

  // Get customer's CFC
  getCustomerCfc(custId: any): Observable<any> {
    let API_URL = `${this.REST_API}/${custId}/cfcActivity`;

    return this.httpClient.get(API_URL).pipe(
      map((res: any) => {
        return res || {};
      }),
      
    );
  }

  getCustomerCfcSummary(custId: any): Promise<any> {
    let applianceCfc = 0;
    let foodCfc = 0;
    let transportCfc = 0;
    let officeCfc = 0;
    let travelCfc = 0;

    return new Promise<{}>((resolve, reject) => {
      this
        .getCustomerCfc(custId)
        .subscribe((res) => {

          console.log('getCustomerCfcSummary', res)

          for (let i = 0; i < res.appliance.length; i++) {
            let cfc = res.appliance[i].cfValue * +res.appliance[i].cfConst;
            applianceCfc += cfc;
          }

          // Calculate food's CFC
          for (let i = 0; i < res.food.length; i++) {
            let cfc = res.food[i].cfValue * +res.food[i].cfConst;
            foodCfc += cfc;
          }

          // Calculate transport's CFC
          for (let i = 0; i < res.transport.length; i++) {
            let cfc = res.transport[i].cfValue * +res.transport[i].cfConst;
            transportCfc += cfc;
          }

          // Calculate office's CFC
          for (let i = 0; i < res.office.length; i++) {
            let cfc = res.office[i].cfValue * +res.office[i].cfConst;
            officeCfc += cfc;
          }

          // Calculate travel's CFC
          for (let i = 0; i < res.travel.length; i++) {
            let cfc = res.travel[i].cfValue * +res.travel[i].cfConst;
            travelCfc += cfc;
          }

          resolve({
            applianceCfc: applianceCfc,
            foodCfc: foodCfc,
            transportCfc: transportCfc,
            officeCfc: officeCfc,
            travelCfc: travelCfc
          });

        });
    });

  }

  // Get customer's CFC
  getCustomerCfcById(custId: any, id: any): Observable<any> {
    let API_URL = `${this.REST_API}/${custId}/cfcActivity/${id}`;

    return this.httpClient.get(API_URL).pipe(
      map((res: any) => {
        return res || {};
      }),
      
    );
  }

  // Add customer's CFC
  addCustomerCfc(custId: any, data: CustomerCfc): Observable<any> {
    let API_URL = `${this.REST_API}/${custId}/cfcActivity`;

    return this.httpClient
      .post(API_URL, data)
      .pipe();
  }

  //Update customer's CFC
  updateCustomerCfc(custId: any, data: any): Observable<any> {
    let API_URL = `${this.REST_API}/${custId}/cfcActivity`;

    return this.httpClient
      .patch(API_URL, data)
      .pipe();
  }

  // get daily cfc
  getDailyCFC(custId: any): Observable<any> {
    let API_URL = `${this.REST_API}/${custId}/dailyCFC`;

    return this.httpClient
      .get(API_URL)
      .pipe(
        map((res: any) => {
          return res || {};
        }),
        
      );
  }

  // add daily cfc
  addDailyCFC(custId: any, data: any): Observable<any> {
    let API_URL = `${this.REST_API}/${custId}/dailyCFC`;

    return this.httpClient
      .post(API_URL, data)
      .pipe();
  }

  // Line chart
  lineMonthlyChart(custId: any): Observable<any> {
    let API_URL = `${this.REST_API}/${custId}/chart/lineMontly`;

    return this.httpClient.get(API_URL)
      .pipe(
        map((res: any) => {
          return res || {};
        }),
        
      );
  }

  lineYearlyChart(custId: any): Observable<any> {
    let API_URL = `${this.REST_API}/${custId}/chart/lineYearly`;

    return this.httpClient.get(API_URL).pipe(
      map((res: any) => {
        return res || {};
      }),
      
    );
  }

  // Donut chart
  donutChart(custId: any): Observable<any> {
    let API_URL = `${this.REST_API}/${custId}/chart/donut`;

    return this.httpClient.get(API_URL).pipe(
      map((res: any) => {
        return res || {};
      }),
      
    );
  }

  // upload image
  uploadImage(data: any): Observable<any> {
    let API_URL = `${environment.apiUrl}/image/upload`;

    return this.httpClient
      .post(API_URL, data)
      .pipe();
  }

}
