import { RouterStateSnapshot } from '@angular/router';
import liff from '@line/liff';
import { environment } from 'src/environments/environment';
import { AccountService } from '../service/account.service';
import { CustomerService } from '../service/customer.service';
import { Customer } from '../shared/models/customer.model';

export function appInitializer(
    accountService: AccountService,
    customerService: CustomerService,
    state: RouterStateSnapshot
) {

    return () => new Promise<void>(async resolve => {

        await liff.init({ liffId: environment.liffLyn });

        if (liff.isLoggedIn()) {
            const idToken = liff.getIDToken();
            const decoded = liff.getDecodedIDToken();

            console.log('id_token', idToken);
            console.log('client_id', decoded.aud);
            
            // accountService.setAccessToken(accessToken);
            accountService.setIdToken(idToken);
            accountService.setClientId(decoded.aud!);

            const profile = await liff.getProfile();
            accountService.setLineProfile(profile);

            customerService.findByLineUserId(profile.userId).subscribe(res => {

                if (res && res.customerId) {

                    customerService
                        .getCustomer(res.customerId)
                        .subscribe(res => {
                            accountService.setUser(res);
                        })
                        .add(resolve);

                    // -- manual authen
                    // accountService
                    //     .authen(res.customerId, profile.userId)
                    //     .subscribe(auth => {
                    //         if (auth) {
                    //             accountService.setAccessToken(auth.access_token);
                    //             resolve()
                    //         }
                    //     })

                } else {

                    const decodedData = liff.getDecodedIDToken()!;
                    const email = decodedData.email!;
                    const name = decodedData.name!;
                    let names = [];

                    if (name)
                        names = name.split(' ', 2);

                    // Set customer info
                    let newCustomer = new Customer();
                    newCustomer.profileImage = profile.pictureUrl;
                    newCustomer.firstName = names.length ? names.shift() : name;
                    newCustomer.lastName = names.join(" ");
                    newCustomer.email = email;
                    newCustomer.status = "ผู้ติดตาม";
                    newCustomer.lineUserId = profile.userId;

                    // Add customer
                    customerService
                        .addCustomer(newCustomer)
                        .subscribe(res => {
                            newCustomer.customerId = res.customerId;
                            console.log("Add new customer success", newCustomer);
                            accountService.setUser(newCustomer)
                                .then(res => {
                                    window.location.href = '/register/pdpa';
                                });

                            // resolve();

                            // -- manual authen
                            // accountService.authen(res.customerId, profile.userId).subscribe(auth => {
                            //     if (auth) {
                            //         accountService.setAccessToken(auth.access_token);
                            //         resolve()
                            //     }
                            // })

                        });
                }
            });

            // resolve();
        } else {
            console.log('test state', state);
            liff.login();
        }

    });
}