import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccountService } from '../service/account.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private accountService: AccountService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log(state.url);
        if (state.url === '/logout') {
            this.accountService.logout();
            return true;
        }
        
        const token = this.accountService.getAuthToken();
        // console.log('auth token', token);
        // console.log('auth route', route);
        // console.log('auth state', state);
        if (token) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        // this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}