import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import liff from "@line/liff/dist/lib";
import { AuthGuard } from "./middlewares/auth.guard";

const routes: Routes = [
  {
    path: "home",
    loadChildren: () =>import("./home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "logout",
    canActivate: [AuthGuard],
    loadChildren: () => import('./register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'register',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: "member-view",
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import("./profile/member/member-view/member-view.module").then(
        (m) => m.MemberViewPageModule
      ),
  },
  {
    path: "follower-view",
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import("./profile/follower/follower-view/follower-view.module").then(
        (m) => m.FollowerViewPageModule
      ),
  },
  {
    path: "cfcalc-main",
    loadChildren: () =>
      import("./cfcalc/cfcalc-main/cfcalc-main.module").then(
        (m) => m.CfcalcMainPageModule
      ),
  },
  {
    path: "cfcalc-intro",
    loadChildren: () => import("./cfcalc/cfcalc-intro/cfcalc-intro.module").then((m) => m.CfcalcIntroPageModule),
  },
  {
    path: 'cfcalc-intro2',
    loadChildren: () => import('./cfcalc/cfcalc-intro2/cfcalc-intro2.module').then(m => m.CfcalcIntro2PageModule)
  },
  {
    path: "cfcalc0",
    loadChildren: () =>
      import("./cfcalc/cfcalc0/cfcalc0.module").then(
        (m) => m.Cfcalc0PageModule
      ),
  },
  {
    path: "cfcalc1",
    loadChildren: () =>
      import("./cfcalc/cfcalc1/cfcalc1.module").then(
        (m) => m.Cfcalc1PageModule
      ),
  },
  {
    path: "cfcalc2",
    loadChildren: () =>
      import("./cfcalc/cfcalc2/cfcalc2.module").then(
        (m) => m.Cfcalc2PageModule
      ),
  },
  {
    path: "cfcalc3",
    loadChildren: () =>
      import("./cfcalc/cfcalc3/cfcalc3.module").then(
        (m) => m.Cfcalc3PageModule
      ),
  },
  {
    path: "cfcalc4",
    loadChildren: () =>
      import("./cfcalc/cfcalc4/cfcalc4.module").then(
        (m) => m.Cfcalc4PageModule
      ),
  },
  {
    path: "cfcalc5",
    loadChildren: () =>
      import("./cfcalc/cfcalc5/cfcalc5.module").then(
        (m) => m.Cfcalc5PageModule
      ),
  },
  {
    path: "cfcalc-dairy",
    loadChildren: () =>
      import("./cfcalc/cfcalc-dairy/cfcalc-dairy.module").then(
        (m) => m.CfcalcDairyPageModule
      ),
  },
  {
    path: "cfcalc-static",
    loadChildren: () =>
      import("./cfcalc/cfcalc-static/cfcalc-static.module").then(
        (m) => m.CfcalcStaticPageModule
      ),
  },
  {
    path: "cfcalc-reader",
    loadChildren: () =>
      import("./cfcalc/cfcalc-reader/cfcalc-reader.module").then(
        (m) => m.CfcalcReaderPageModule
      ),
  },
  {
    path: 'redeems',
    loadChildren: () => import('./redeem/redeem.module').then(m => m.RedeemPageModule)
  },
  {
    path: 'profile-edit',
    loadChildren: () => import('./profile/edit/edit.module').then(m => m.EditPageModule)
  },
  {
    path: 'profile-image',
    loadChildren: () => import('./profile/image/image.module').then(m => m.ImagePageModule)
  },
  {
    path: 'share-page',
    loadChildren: () => import('./share-page/share-page.module').then(m => m.SharePagePageModule)
  },
  {
    path: 'missions',
    loadChildren: () => import('./missions/missions.module').then(m => m.MissionsPageModule)
  },
  {
    path: 'cfcalc-stat',
    loadChildren: () => import('./cfcalc/cfcalc-stat/cfcalc-stat.module').then(m => m.CfcalcStatPageModule)
  },
  {
    path: 'scan',
    loadChildren: () => import('./scan/scan.module').then(m => m.ScanPageModule)
  },
  {
    path: 'ecolife',
    loadChildren: () => import('./ecolife/ecolife.module').then(m => m.EcolifePageModule)
  },
  {
    path: 'partner',
    loadChildren: () => import('./partner/partner.module').then(m => m.PartnerPageModule)
  },
  {
    path: 'unauthorize',
    loadChildren: () => import('./errors/unauthorize/unauthorize.module').then(m => m.UnauthorizePageModule)
  },
  {
    path: 'server-error',
    loadChildren: () => import('./errors/server-error/server-error.module').then(m => m.ServerErrorPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
